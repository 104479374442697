import jquery from "jquery";
window.jQuery = window.$ = jquery;

// Bootstrap Framework
import "./scss/index.scss";
import "@popperjs/core";
import { Modal } from "bootstrap";
window.Modal = Modal;

import "slick-carousel";
import hcSticky from "hc-sticky";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import lightGallery from "lightgallery";

import axios from "axios";

//#region bootstrap navbar + hamburger + body scroll lock
var navbarMain = document.getElementById("navbarMain");
navbarMain.addEventListener("show.bs.collapse", function () {
  $(".navbar-toggler .hamburger").addClass("is-active");
  let scrollableNav = document.querySelector("#navbarMain");
  disableBodyScroll(scrollableNav);
});
navbarMain.addEventListener("hidden.bs.collapse", function () {
  $(".navbar-toggler .hamburger").removeClass("is-active");
  let scrollableNav = document.querySelector("#navbarMain");
  enableBodyScroll(scrollableNav);
});
//#endregion

//#region stickybar
document.addEventListener("DOMContentLoaded", function () {
  new hcSticky(".stickybar", {
    stickTo: "body",
    stickyClass: "stickybar--sticked",
  });
});
//#endregion

//#region sliders
$(function () {
  //#region main slider homepage
  $(".slider-main").slick({
    arrows: false,
    centerMode: true,
    variableWidth: true,
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
  });
  $(".slider-scooter").slick({
    arrows: false,
    centerMode: true,
    variableWidth: true,
  });
  //#endregion
});
//#endregion
//#region light gallery
if ($(".gallery__lightgallery-scooter").length) {
  let lg = document.getElementById("js-lightgallery");
  lg.addEventListener("lgAfterOpen", () => {
    let scrollableLg = document.querySelector(".lg-container");
    disableBodyScroll(scrollableLg);
  });
  lg.addEventListener("lgAfterClose", () => {
    let scrollableLg = document.querySelector(".lg-container");
    enableBodyScroll(scrollableLg);
  });
  lightGallery(lg, {
    selector: ".gallery__lightgallery-scooter__1",
    mobileSettings: {
      controls: true,
      showCloseIcon: true,
      download: true,
    },
  });
  lightGallery(lg, {
    selector: ".gallery__lightgallery-scooter__2",
    mobileSettings: {
      controls: true,
      showCloseIcon: true,
      download: true,
    },
  });
  lightGallery(lg, {
    selector: ".gallery__lightgallery-scooter__3",
    mobileSettings: {
      controls: true,
      showCloseIcon: true,
      download: true,
    },
  });
  lightGallery(lg, {
    selector: ".gallery__lightgallery-scooter__4",
    mobileSettings: {
      controls: true,
      showCloseIcon: true,
      download: true,
    },
  });
  lightGallery(lg, {
    selector: ".gallery__lightgallery-scooter__5",
    mobileSettings: {
      controls: true,
      showCloseIcon: true,
      download: true,
    },
  });
}
if ($(".gallery__lightgallery-sk3").length) {
  let lg = document.getElementById("js-lightgallery-sk3");
  lg.addEventListener("lgAfterOpen", () => {
    let scrollableLg = document.querySelector(".lg-container");
    disableBodyScroll(scrollableLg);
  });
  lg.addEventListener("lgAfterClose", () => {
    let scrollableLg = document.querySelector(".lg-container");
    enableBodyScroll(scrollableLg);
  });
  lightGallery(lg, {
    selector: ".gallery__lightgallery-sk3__1",
    mobileSettings: {
      controls: true,
      showCloseIcon: true,
      download: true,
    },
  });
  lightGallery(lg, {
    selector: ".gallery__lightgallery-sk3__2",
    mobileSettings: {
      controls: true,
      showCloseIcon: true,
      download: true,
    },
  });
  lightGallery(lg, {
    selector: ".gallery__lightgallery-sk3__3",
    mobileSettings: {
      controls: true,
      showCloseIcon: true,
      download: true,
    },
  });
  lightGallery(lg, {
    selector: ".gallery__lightgallery-sk3__4",
    mobileSettings: {
      controls: true,
      showCloseIcon: true,
      download: true,
    },
  });
  lightGallery(lg, {
    selector: ".gallery__lightgallery-sk3__5",
    mobileSettings: {
      controls: true,
      showCloseIcon: true,
      download: true,
    },
  });
}

if ($(".gallery__lightgallery").length) {
  let lg = document.getElementById("js-lightgallery-dettaglio");
  lg.addEventListener("lgAfterOpen", () => {
    let scrollableLg = document.querySelector(".lg-container");
    disableBodyScroll(scrollableLg);
  });
  lg.addEventListener("lgAfterClose", () => {
    let scrollableLg = document.querySelector(".lg-container");
    enableBodyScroll(scrollableLg);
  });
  lightGallery(lg, {
    selector: ".gallery__lightgallery",
    mobileSettings: {
      controls: true,
      showCloseIcon: true,
      download: true,
    },
  });
}
if ($(".gallery__lightgallery__sk3").length) {
  let lg = document.getElementById("js-lightgallery-dettaglio-sk3");
  lg.addEventListener("lgAfterOpen", () => {
    let scrollableLg = document.querySelector(".lg-container");
    disableBodyScroll(scrollableLg);
  });
  lg.addEventListener("lgAfterClose", () => {
    let scrollableLg = document.querySelector(".lg-container");
    enableBodyScroll(scrollableLg);
  });
  lightGallery(lg, {
    selector: ".gallery__lightgallery__sk3",
    mobileSettings: {
      controls: true,
      showCloseIcon: true,
      download: true,
    },
  });
}
//#endregion
//#region animated numbers

const counterAnim = (qSelector, start = 0, end, duration = 1000) => {
  const target = document.querySelector(qSelector);
  let startTimestamp = null;
  const step = (timestamp) => {
    if (!startTimestamp) startTimestamp = timestamp;
    const progress = Math.min((timestamp - startTimestamp) / duration, 1);
    target.innerText = Math.floor(progress * (end - start) + start);
    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
};

document.addEventListener("DOMContentLoaded", () => {
  if ($("#potenza-sk1").length) {
    counterAnim("#potenza-sk1", 10, 2000, 1000);
    counterAnim("#ricarica-sk1", 0, 6, 1500);
    counterAnim("#batteria-sk1", 10, 64, 2000);
    counterAnim("#batterie-sk1", 10, 128, 2500);
  }

  if ($("#potenza-sk3").length) {
    counterAnim("#potenza-sk3", 10, 2000, 1000);
    counterAnim("#ricarica-sk3", 0, 6, 1500);
    counterAnim("#batteria-sk3", 10, 64, 2000);
    counterAnim("#batterie-sk3", 10, 128, 2500);
  }
  if ($("#potenza-rapido").length) {
    counterAnim("#potenza-rapido", 10, 6000, 1000);
    counterAnim("#ricarica-rapido", 0, 6, 1500);
    counterAnim("#batteria-rapido", 10, 100, 2000);
    counterAnim("#batterie-rapido", 10, 100, 2500);
  }
});
//#endregion

//#region instafeed
$(function () {
  axios
    .get(
      "https://graph.facebook.com/v14.0/17841450707898358/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,username,timestamp&limit=12&access_token=EAAFqAekfFbwBAFy4PGSFBmougFWVIkZB7EvvZAZC900ZBn01acZCxn6W68VLSAfa3NrwOFKAwGstHl8kNLsIZBAhjEXhn4helpb6QHmxQDK996DhxunZBZBMWPGOgO0wEzIyZALirZBzvfLBLCdfZBRP0mrLPwFwqD8QSc9cOAYWBzdkG66GS1VgUq190I6ZBWpmdYenFgGu9i59XAZDZD"
    )
    .then((res) => {
      let feed = res.data.data;
      for (let i = 0; i < feed.length; i++) {
        if (feed[i].media_type == "IMAGE") {
          let html = `<a href="${feed[i].permalink}" target="_blank"><img src="${feed[i].media_url}"></a>`;
          $("#instafeed").append($(html));
        }
      }
    })
    .catch((err) => {
      console.log(err);
    });
});
//#endregion
